<template>
  <div class="panel-stake">
    <div class="flex flex-column sm:flex-row justify-content-between">
      <div class="flex sm:flex-order-2 sm:ml-auto align-items-center mb-s3 sm:mb-s0">
        <Button
          :label="$t('yieldPool.stake')"
          class="w-6 button-base mr-s1"
          :class="{ active: tabActive === 'stake' }"
          @click="setTabActive('stake')"
        />
        <Button
          :label="$t('yieldPool.unstake')"
          class="w-6 button-base ml-s1"
          :class="{ active: tabActive === 'unstake' }"
          @click="setTabActive('unstake')"
        />
      </div>

      <div class="sm:flex gutter-x-s6 gutter-y-s3">
        <div>
          <Caption
            :caption="$t('yieldPool.auto.totalCompound.caption')"
            :tooltip="$t('yieldPool.auto.totalCompound.tooltip')"
            class="mb-s1"
          />
          <div
            class="flex gutter-s1"
            :class="
              $store.state.layout.isTablet ? 'flex-column align-items-start' : 'align-items-center'
            "
          >
            <div>{{ format(pool.printTotalCompound) }} BLUES</div>
            <div :class="$store.state.layout.isTablet ? 'text-muted text-sm' : 'text-muted'">
              <span v-if="!$store.state.layout.isTablet">/</span>
              ${{ format(pool.printTotalCompoundUSD) }}
            </div>
          </div>
        </div>
        <div>
          <Caption
            :caption="$t('yieldPool.auto.availableCompound.caption')"
            :tooltip="$t('yieldPool.auto.availableCompound.tooltip')"
            class="mb-s1"
          />
          <div
            class="flex gutter-s1"
            :class="
              $store.state.layout.isTablet ? 'flex-column align-items-start' : 'align-items-center'
            "
          >
            <div>{{ format(pool.printAvailableCompound) }} BLUES</div>
            <div :class="$store.state.layout.isTablet ? 'text-muted text-sm' : 'text-muted'">
              <span v-if="!$store.state.layout.isTablet">/</span>
              ${{ format(pool.printAvailableCompoundUSD) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="w-full flex-grow-1 mt-s3">
        <InputPanel
          :action="tabActive"
          :pool="pool"
          pool-type="auto"
          :isMaxUnstakeMode="isMaxUnstakeMode"
          :setMaxUnstakeMode="onSetMaxUnstakeMode"
          @hasValidationError="
            this.disableButton = $event.hasError || inProgress;
            this.inputValue = $event.value;
          "
          v-model="inputValue"
          modelValue="inputValue"
        />
      </div>

      <div
        class="flex w-full sm:w-18rem sm:flex-shrink-0 mt-s3 sm:pl-s3 flex-column justify-content-between"
      >
        <UnstackingFeeTimer
          v-if="checkTimer && tabActive === 'unstake' && pool.printAvailableCompound.gt(0)"
          :time="pool.userTimeFeeEnd.toNumber()"
          :percent="pool.withdrawFee.toFixed()"
          class="mx-auto mt-s4 sm:mt-s0"
          @endTimer="isTimer = $event"
        />
        <FilledListItem
          v-else
          :caption="$t('inUsd')"
          :data="`$${format(pool.calculateUSD(this.inputValue ?? 0))}`"
        />
        <Button
          class="w-full button-primary mt-s3 button-lg"
          @click="onDialogOpen(tabActive)"
          :disabled="disableButton"
        >
          <Spinner color="white" size="24" class="mr-s2" v-if="inProgress" />
          {{ inProgress ? $t('inProgress') : $t(`yieldPool.${tabActive}Btn`) }}
        </Button>
      </div>
    </div>

    <Dialog
      v-model:visible="isShowDialog"
      :header="getConfirmDialogHeader()"
      dismissableMask
      :modal="true"
      :close-on-escape="closeOnEscape"
      :content-class="$store.state.layout.isMobile ? 'scroll-container' : ''"
    >
      <component
        :is="tabActive === 'stake' ? 'StakeConfirmation' : 'UnstakeConfirmation'"
        :in-progress="inProgress"
        :amount="this.inputValue"
        :amount-usd="pool.calculateUSD(this.inputValue)"
        :token="pool.token"
        :cancel="onDialogClose"
        :confirm="onConfirm"
        :checkTimer="checkTimer"
        :pool="pool"
      >
      </component>
    </Dialog>
  </div>
</template>

<script>
import InputPanel from '@/views/pages/investment/staking/pool/stake/InputPanel';
import FilledListItem from '@/components/filledList/FilledListItem';
import StakeConfirmation from './confirmation/StakeConfirmation';
import UnstakeConfirmation from './confirmation/UnstakeConfirmation';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import notificationsMixin from '@/mixins/notifications.mixin';
import { mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import Caption from '@/components/Caption';
import Spinner from '@/components/Spinner';
import UnstackingFeeTimer from './unstackingFeeTimer/UnstackingFeeTimer';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { isNestedDialogOpen } from '@/composables/utils/useUtils';
import { useBalances } from '@/store/modules/tokens/useBalances';

const MAP_ACTIONS = {
  STAKING: {
    getStatusStaking: 'getStatusStaking',
    updateStakingPools: 'updateStakingPools',
    checkAllowance: 'checkAllowance',
    stakeLqf: 'stakeLqf',
    unStakeLqf: 'unStakeLqf',
  },
  AUTO_STAKING: {
    getStatusStaking: 'getStatusAutoStaking',
    updateStakingPools: 'updateAutoStakingPools',
    checkAllowance: 'checkAllowanceAutoStaking',
    stakeLqf: 'stakeLqfAutoStaking',
    unStakeLqf: 'unStakeLqfAutoStaking',
    unStakeMaxLqf: 'unStakeMaxLqfAutoStaking',
  },
};

export default {
  name: 'Stake',
  mixins: [formatNumbersMixin, notificationsMixin],
  components: {
    UnstackingFeeTimer,
    Caption,
    InputPanel,
    FilledListItem,
    StakeConfirmation,
    UnstakeConfirmation,
    Spinner,
  },
  props: {
    pool: Object,
    autoStaking: { type: Boolean, default: false },
  },
  emits: ['openDocs'],
  setup() {
    const { updateTokenBalances } = useBalances();

    return {
      updateTokenBalances,
    };
  },
  data() {
    return {
      tabActive: 'stake',
      isShowDialog: false,
      inputValue: '',
      disableButton: true,
      inProgress: false,
      isTimer: true,
      isMaxUnstakeMode: false,
    };
  },
  async created() {
    try {
      this.updateInterval = setInterval(async () => {
        await this.getStatusAutoStaking();
        await this.updateAutoStakingInfo();
        await this.updateAutoStakingPools();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
      checkAllowance: STAKING_ACTION_TYPES.CHECK_ALLOWANCE,
      stakeLqf: STAKING_ACTION_TYPES.STAKE_LQF,
      unStakeLqf: STAKING_ACTION_TYPES.UNSTAKE_LQF,
    }),
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStakingPools: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
      checkAllowanceAutoStaking: AUTO_STAKING_ACTION_TYPES.CHECK_ALLOWANCE_AUTO_STAKING,
      stakeLqfAutoStaking: AUTO_STAKING_ACTION_TYPES.STAKE_LQF_AUTO_STAKING,
      unStakeLqfAutoStaking: AUTO_STAKING_ACTION_TYPES.UNSTAKE_LQF_AUTO_STAKING,
      unStakeMaxLqfAutoStaking: AUTO_STAKING_ACTION_TYPES.UNSTAKE_MAX_LQF_AUTO_STAKING,
      updateAutoStakingInfo: AUTO_STAKING_ACTION_TYPES.UPDATE_INFO,
    }),
    setTabActive(tab) {
      this.tabActive = tab;
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    getConfirmDialogHeader() {
      const poolTitle = this.$t(`yieldPool.auto.poolTitle.caption`);
      return this.$t(`yieldPool.${this.tabActive}ConfirmDialog.header`, { poolTitle: poolTitle });
    },
    onSetMaxUnstakeMode(mode) {
      this.isMaxUnstakeMode = mode;
    },
    async onConfirm() {
      const ACTIONS = this.autoStaking ? MAP_ACTIONS.AUTO_STAKING : MAP_ACTIONS.STAKING;
      const ACTION = this.tabActive;

      this.inProgress = true;
      this.disableButton = true;
      this.onDialogClose();

      const poolTemplate = `
        ${this.autoStaking ? this.$t('autoLqfPool') : this.$t('manualLqfPool')}
      `;
      const stakingTemplate = `${this.$t('staking')} BLUES ${this.$t('to')} ${poolTemplate}`;
      const stakingSucceededTemplate = `${stakingTemplate} ${this.$t('succeeded')}`;
      const stakingFailedTemplate = `${stakingTemplate} ${this.$t('failed')}`;

      const unstakingTemplate = `${this.$t('unstaking')} BLUES ${this.$t('from')} ${poolTemplate}`;
      const unstakingSucceededTemplate = `${unstakingTemplate} ${this.$t('succeeded')}`;
      const unstakingFailedTemplate = `${unstakingTemplate} ${this.$t('failed')}`;

      this.showInProgressNotification(
        `
        ${ACTION === 'stake' ? stakingTemplate : unstakingTemplate}
      `,
        'yieldPools',
      );
      try {
        if (ACTION === 'stake') {
          await this[ACTIONS.checkAllowance]({ pool: this.pool, amount: this.inputValue });
          await this[ACTIONS.stakeLqf]({ pool: this.pool, amount: this.inputValue });
        } else if (ACTION === 'unstake' && this.isMaxUnstakeMode) {
          await this[ACTIONS.unStakeMaxLqf]({ pool: this.pool });
          this.isMaxUnstakeMode = false;
        } else {
          await this[ACTIONS.unStakeLqf]({ pool: this.pool, amount: this.inputValue });
        }
        await this.updateTokenBalances();
        this.inputValue = '';
        await this[ACTIONS.getStatusStaking]();
        await this.updateAutoStakingInfo();
        await this[ACTIONS.updateStakingPools]();
        this.closeNotifications('yieldPools');
        this.showSuccessNotification(
          `
          ${ACTION === 'stake' ? stakingSucceededTemplate : unstakingSucceededTemplate}
        `,
          'yieldPools',
        );
      } catch (error) {
        console.error('[POOL: STAKE/UNSTAKE] Happen error: ', error);
        this.closeNotifications('yieldPools');
        this.showErrorNotification(
          `
          ${ACTION === 'stake' ? stakingFailedTemplate : unstakingFailedTemplate}
        `,
          'yieldPools',
        );
        this.inputValue = '';
      }
      this.inProgress = false;
      this.disableButton = true;
    },
  },
  computed: {
    checkTimer() {
      const currentTime = new Date().getTime();
      return currentTime < this.pool.userTimeFeeEnd.toNumber() * 1000;
    },
    closeOnEscape() {
      return !isNestedDialogOpen.value;
    },
  },
};
</script>

<style scoped></style>
