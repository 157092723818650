<template>
  <ControlPanelCollapse :contract-url="getContractUrl()" toggle-inline get-blues-button>
    <template v-slot:header-title>
      <PoolTitle />
    </template>

    <template v-slot:header-data>
      <div class="flex flex-wrap gutter-y-s3 flex-grow-1 xl:justify-content-center">
        <div class="w-12 sm:w-4 xl:col-offset-1">
          <Caption
            :caption="$t('yieldPool.manual.apr.caption')"
            :tooltip="$t('yieldPool.manual.apr.tooltip')"
            class="mb-s1"
          />
          <div class="flex mr-s2 align-items-center">
            {{ printPercent(pool.printApr) }}
            <LearnMoreDialog data-source="staking" type="APR" as-icon />
          </div>
          <div class="text-sm text-primary mt-s1 text-bolder" v-if="isReferral">
            {{
              $t('referral.rewardsInfo.percentAsReferral', {
                percent: printPercent(getAdditionalApr()),
              })
            }}
          </div>
        </div>
        <div class="w-12 sm:w-4 lg:w-3">
          <Caption
            :caption="$t('yieldPool.manual.totalStake.caption')"
            :tooltip="$t('yieldPool.manual.totalStake.tooltip')"
            class="mb-s1 md:mb-s1"
          />
          <div class="mr-s2">{{ format(pool.printTotalStake) }} BLUES</div>
        </div>
        <div class="w-12 sm:w-4 lg:w-3">
          <Caption
            :caption="$t('yieldPool.manual.newEarnings.caption')"
            :tooltip="$t('yieldPool.manual.newEarnings.tooltip')"
            class="mb-s1 md:mb-s1"
          />
          <div class="mr-s2">{{ format(pool.printYouEarned) + ' BLUES' }}</div>
        </div>
      </div>
    </template>

    <div class="grid grid-nogutter">
      <div class="col-12 xl:col-9 xl:flex-order-2">
        <Stake :auto-staking="autoStaking" :pool="pool" class="h-full" />
      </div>
      <div class="col-12 xl:col-3 xl:pr-s3 mt-s3 xl:mt-s0">
        <Collect :pool="pool" class="h-full" />
      </div>
    </div>
  </ControlPanelCollapse>
</template>

<script>
import PoolTitle from './poolTitle/PoolTitle';
import Caption from '@/components/Caption';
import Collect from './collect/Collect';
import Stake from './stake/Stake';
import roundMixin from '@/mixins/round.mixin';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import ControlPanelCollapse from '@/components/ControlPanelCollapse';
import { getScanLink } from '@/sdk/utils';
import LearnMoreDialog from '@/components/highRatesLearnMore/LearnMoreDialog';
import { useReferral } from '@/store/modules/referral/useReferral';
import { APR_INCREASE_PERCENT } from '@/constants/REFERRAL_PARAMS';

export default {
  name: 'Pool',
  mixins: [roundMixin, formatNumbersMixin],
  components: {
    LearnMoreDialog,
    Stake,
    PoolTitle,
    Collect,
    Caption,
    ControlPanelCollapse,
  },
  props: {
    pool: Object,
    autoStaking: { type: Boolean, default: false },
  },
  data() {
    return {
      iconSrc: '/images/tokens/blues.svg',
    };
  },
  methods: {
    printPercent(val) {
      if (val.lt(1)) {
        return `< 1%`;
      }
      return val.gt(10000) ? `> 10000%` : `${this.mathMixRound(val, 2)}%`;
    },
    getAdditionalApr() {
      const baseApr = this.pool.printApr
        .dividedBy(100 / 1 + APR_INCREASE_PERCENT.REFERRAL)
        .multipliedBy(100);
      return baseApr.multipliedBy(APR_INCREASE_PERCENT.REFERRAL).dividedBy(100);
    },
    getContractUrl() {
      return getScanLink(this.pool.farm, 'address');
    },
  },
  computed: {
    tvlLevels() {
      const tvlLevels = this.$store.state.staking.tvlLevels;
      return tvlLevels.map(level => this.format(level).toUpperCase());
    },
    aprWeights() {
      return this.$store.state.staking.aprWeights;
    },
    isReferral() {
      const { referralState } = useReferral();
      return referralState.isReferral;
    },
  },
};
</script>

<style scoped lang="scss"></style>
