<template>
  <div class="panel-stake">
    <div class="flex flex-column sm:flex-row justify-content-between">
      <div class="flex sm:flex-order-2 sm:ml-auto align-items-center mb-s3 sm:mb-s0">
        <Button
          :label="$t('yieldPool.stake')"
          class="w-6 button-base mr-s1"
          :class="{ active: tabActive === 'stake' }"
          @click="setTabActive('stake')"
        />
        <Button
          :label="$t('yieldPool.unstake')"
          class="w-6 button-base ml-s1"
          :class="{ active: tabActive === 'unstake' }"
          @click="setTabActive('unstake')"
        />
      </div>

      <div>
        <Caption
          :caption="$t('yieldPool.manual.youStaked.caption')"
          :tooltip="$t('yieldPool.manual.youStaked.tooltip')"
        />
        <div class="flex flex-column sm:flex-row align-items-start sm:align-items-center">
          <div class="mt-s1 mr-s2">
            {{ format(pool.printYouStaked) }} BLUES
            <span class="text-muted ml-s1">/ ${{ format(pool.printYouStakedUSD) }}</span>
          </div>
          <Button
            class="button-text mt-s1"
            :label="$t('getBlues')"
            @click="
              $router.push(`/swap/${selectedNetCurrency}/BLUES`);
              $emit('click');
            "
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="w-full md:w-8 mt-s3">
        <InputPanel
          :action="tabActive"
          :pool="pool"
          pool-type="manual"
          @hasValidationError="
            this.disableButton = $event.hasError || inProgress;
            this.inputValue = $event.value;
          "
          v-model="inputValue"
          modelValue="inputValue"
        />
      </div>

      <div class="w-full sm:w-4 mt-s3 sm:pl-s3 align-self-center">
        <FilledListItem
          :caption="$t('inUsd')"
          :data="`$${format(pool.calculateUSD(this.inputValue ?? 0))}`"
        />
        <Button
          class="w-full button-primary mt-s3 button-lg"
          @click="onDialogOpen(tabActive)"
          :disabled="disableButton"
        >
          <Spinner color="white" size="24" class="mr-s2" v-if="inProgress" />
          {{ inProgress ? $t('inProgress') : $t(`yieldPool.${tabActive}Btn`) }}
        </Button>
      </div>
    </div>

    <Dialog
      v-model:visible="isShowDialog"
      :header="getConfirmDialogHeader()"
      dismissableMask
      :modal="true"
      :content-class="$store.state.layout.isMobile ? 'scroll-container' : ''"
    >
      <Confirmation
        :in-progress="inProgress"
        :amount="this.inputValue"
        :amount-usd="format(pool.calculateUSD(this.inputValue))"
        :token="pool.token"
        :cancel="onDialogClose"
        :confirm="onConfirm"
        :action="tabActive"
      >
      </Confirmation>
    </Dialog>
  </div>
</template>

<script>
import InputPanel from './InputPanel';
import FilledListItem from '@/components/filledList/FilledListItem';
import Confirmation from './confirmation/Confirmation';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import notificationsMixin from '@/mixins/notifications.mixin';
import { mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import Caption from '@/components/Caption';
import Spinner from '@/components/Spinner';
import { NETWORK_ETH_TOKEN_NAME } from '@/helpers/networkParams.helper';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { useBalances } from '@/store/modules/tokens/useBalances';

const MAP_ACTIONS = {
  STAKING: {
    getStatusStaking: 'getStatusStaking',
    updateStakingPools: 'updateStakingPools',
    checkAllowance: 'checkAllowance',
    stakeLqf: 'stakeLqf',
    unStakeLqf: 'unStakeLqf',
  },
  AUTO_STAKING: {
    getStatusStaking: 'getStatusAutoStaking',
    updateStakingPools: 'updateAutoStakingPools',
    checkAllowance: 'checkAllowanceAutoStaking',
    stakeLqf: 'stakeLqfAutoStaking',
    unStakeLqf: 'unStakeLqfAutoStaking',
  },
};

export default {
  name: 'Stake',
  mixins: [formatNumbersMixin, notificationsMixin],
  components: { Caption, InputPanel, FilledListItem, Confirmation, Spinner },
  props: {
    pool: Object,
    autoStaking: { type: Boolean, default: false },
  },
  setup() {
    const { updateTokenBalances } = useBalances();

    return {
      updateTokenBalances,
    };
  },
  data() {
    return {
      tabActive: 'stake',
      isShowDialog: false,
      inputValue: '',
      disableButton: true,
      inProgress: false,
      isTimer: true,
    };
  },
  async created() {
    try {
      this.updateInterval = setInterval(async () => {
        await this.getStatusStaking();
        await this.updateStakingPools();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
      checkAllowance: STAKING_ACTION_TYPES.CHECK_ALLOWANCE,
      stakeLqf: STAKING_ACTION_TYPES.STAKE_LQF,
      unStakeLqf: STAKING_ACTION_TYPES.UNSTAKE_LQF,
    }),
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStakingPools: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
      checkAllowanceAutoStaking: AUTO_STAKING_ACTION_TYPES.CHECK_ALLOWANCE_AUTO_STAKING,
      stakeLqfAutoStaking: AUTO_STAKING_ACTION_TYPES.STAKE_LQF_AUTO_STAKING,
      unStakeLqfAutoStaking: AUTO_STAKING_ACTION_TYPES.UNSTAKE_LQF_AUTO_STAKING,
    }),
    setTabActive(tab) {
      this.tabActive = tab;
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    getConfirmDialogHeader() {
      const poolTitle = this.$t(`yieldPool.manual.poolTitle.caption`);
      return this.$t(`yieldPool.${this.tabActive}ConfirmDialog.header`, { poolTitle: poolTitle });
    },
    async onConfirm() {
      const ACTIONS = this.autoStaking ? MAP_ACTIONS.AUTO_STAKING : MAP_ACTIONS.STAKING;
      const ACTION = this.tabActive;

      this.inProgress = true;
      this.disableButton = true;
      this.onDialogClose();

      const poolTemplate = `
        ${this.autoStaking ? this.$t('autoLqfPool') : this.$t('manualLqfPool')}
      `;
      const stakingTemplate = `${this.$t('staking')} BLUES ${this.$t('to')} ${poolTemplate}`;
      const stakingSucceededTemplate = `${stakingTemplate} ${this.$t('succeeded')}`;
      const stakingFailedTemplate = `${stakingTemplate} ${this.$t('failed')}`;

      const unstakingTemplate = `${this.$t('unstaking')} BLUES ${this.$t('from')} ${poolTemplate}`;
      const unstakingSucceededTemplate = `${unstakingTemplate} ${this.$t('succeeded')}`;
      const unstakingFailedTemplate = `${unstakingTemplate} ${this.$t('failed')}`;

      this.showInProgressNotification(
        `
        ${ACTION === 'stake' ? stakingTemplate : unstakingTemplate}
      `,
        'yieldPools',
      );
      try {
        if (ACTION === 'stake') {
          await this[ACTIONS.checkAllowance]({ pool: this.pool, amount: this.inputValue });
          await this[ACTIONS.stakeLqf]({ pool: this.pool, amount: this.inputValue });
        } else {
          await this[ACTIONS.unStakeLqf]({ pool: this.pool, amount: this.inputValue });
        }
        await this.updateTokenBalances();
        this.inputValue = '';
        await this[ACTIONS.getStatusStaking]();
        await this[ACTIONS.updateStakingPools]();
        this.closeNotifications('yieldPools');
        this.showSuccessNotification(
          `
          ${ACTION === 'stake' ? stakingSucceededTemplate : unstakingSucceededTemplate}
        `,
          'yieldPools',
        );
      } catch (error) {
        console.error('[POOL: STAKE/UNSTAKE] Happen error: ', error);
        this.closeNotifications('yieldPools');
        this.showErrorNotification(
          `
          ${ACTION === 'stake' ? stakingFailedTemplate : unstakingFailedTemplate}
        `,
          'yieldPools',
        );
        this.inputValue = '';
      }
      this.inProgress = false;
      this.disableButton = true;
    },
  },
  computed: {
    checkTimer() {
      const currentTime = new Date().getTime();
      return currentTime < this.pool.userTimeFeeEnd.toNumber() * 1000;
    },
    selectedNetCurrency() {
      return NETWORK_ETH_TOKEN_NAME;
    },
  },
};
</script>

<style scoped></style>
