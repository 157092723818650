<template>
  <div
    class="flex-column input-panel"
    :class="{ 'panel-focus': focus, 'panel-error': isBalanceError }"
    v-if="pool"
  >
    <div
      class="flex flex-wrap w-full mb-2 md:flex-nowrap align-items-center justify-content-end md:justify-content-between"
    >
      <div class="mr-auto caption" v-if="!$store.state.layout.isMobile">
        {{ $t(`yieldPool.${action}`) }}
      </div>
      <InputBalance
        :balance="getBalance()"
        :is-balance-error="isBalanceError"
        :is-max-disabled="eq(getBalance().value, 0)"
        @setMax="onSetMax"
      />
    </div>
    <div class="flex w-full align-items-center justify-content-between">
      <TokenIcon
        icon-src="/images/tokens/blues.svg"
        symbol="BLUES"
        size="20"
        as-label
        full
        v-if="!$store.state.layout.isMobile"
      />
      <FormattedInput
        v-model="internalValue"
        :disabled="disabled"
        v-model:focus="focus"
        class="w-full p-0 text-right border-none inputtext-lg ml-s2"
        @update:modelValue="value => onChangeInput(value)"
      />
    </div>
  </div>
</template>

<script>
import TokenIcon from '@/components/token/TokenIcon';
import FormattedInput from '@/components/FormattedInput';
import BigNumber from 'bignumber.js';
import { BN_ZERO } from '@/sdk/constants';
import { NATIVE_TOKEN_SYMBOL } from '@/helpers/constants';
import compareMixin from '@/mixins/compare.mixin';
import InputBalance from '@/components/form/InputBalance';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';

export default {
  name: 'InputPanel',
  components: { InputBalance, TokenIcon, FormattedInput },
  mixins: [compareMixin],
  emits: ['update:modelValue', 'hasValidationError'],
  props: {
    action: String,
    pool: Object,
    poolType: String,
    setMaxUnstakeMode: Function,
    isMaxUnstakeMode: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    modelValue: String,
  },
  setup() {
    const { getTokensWithFilter } = useTokens();
    const { balances, getBalanceByToken } = useBalances();

    return {
      balances,
      getTokensWithFilter,
      getBalanceByToken,
    };
  },
  watch: {
    action: {
      handler: async function () {
        this.onChangeInput(this.internalValue);
      },
      immediate: true,
    },
    modelValue: {
      handler(value) {
        this.onChangeInput(value, this.isMaxUnstakeMode);
      },
    },
    [`balances.${NATIVE_TOKEN_SYMBOL}`]: {
      handler() {
        if (this.isMaxUnstakeMode && this.isUnstakeFromAutoPool) {
          this.onChangeInput(this.pool.printAvailableCompound.toFixed(), this.isMaxUnstakeMode);
        } else {
          this.onChangeInput(this.internalValue);
        }
      },
    },
  },
  data: function () {
    return {
      internalValue: '',
      isBalanceError: false,
      focus: false,
    };
  },
  methods: {
    getBalance() {
      const stakeBalance = this.printLqfBalance.toExact();
      const unstakeBalance =
        this.poolType === 'manual'
          ? this.pool.printYouStaked.toFixed()
          : this.pool.printAvailableCompound.toFixed();

      return { value: this.action === 'stake' ? stakeBalance : unstakeBalance, symbol: 'BLUES' };
    },
    onChangeInput(value, isChangeFromMaxMode = false) {
      if (!isChangeFromMaxMode && this.isUnstakeFromAutoPool) {
        this.setMaxUnstakeMode(false);
      }
      this.internalValue = value;
      this.isBalanceError = false;
      let hasBalanceError = false;
      let hasValidationError = true;
      let hasDecimalsError = false;
      let returnValue;
      if (this.internalValue !== '') {
        returnValue = new BigNumber(this.internalValue);
        hasValidationError = returnValue.lte(0);
        this.isBalanceError = hasBalanceError = returnValue.gt(this.getBalance().value);
      }
      this.$emit('hasValidationError', {
        hasError: hasValidationError || hasDecimalsError || hasBalanceError,
        value: hasDecimalsError ? BN_ZERO.toString() : returnValue?.toFixed(),
      });
      this.$emit('update:modelValue', this.internalValue);
    },
    onSetMax() {
      if (this.isUnstakeFromAutoPool) {
        this.setMaxUnstakeMode(true);
      }
      this.internalValue = this.getBalance().value;
      this.onChangeInput(this.internalValue, true);
    },
    clearInput() {
      this.internalValue = '';
    },
  },
  computed: {
    printLqfBalance() {
      const symbol = NATIVE_TOKEN_SYMBOL;
      return this.getBalanceByToken(this.getTokensWithFilter({ symbol }))?.balance;
    },
    isUnstakeFromAutoPool() {
      return this.action === 'unstake' && this.poolType === 'auto';
    },
  },
};
</script>

<style scoped></style>
