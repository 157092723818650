<template>
  <div class="flex panel-stake flex-column sm:justify-content-between sm:flex-row xl:flex-column">
    <div class="flex-grow-1">
      <div class="flex align-items-center justify-content-between mb-s3">
        <span class="text-sm">{{ $t('yieldPool.manual.youEarned') }}</span>
        <LearnMoreDialog data-source="staking" type="APR" as-link />
      </div>
      <div class="flex flex-column sm:flex-row xl:flex-column gutter-s2">
        <FilledListItem
          :caption="$t('yieldPool.manual.total.caption')"
          :tooltip="$t('yieldPool.manual.total.tooltip')"
          class="flex-grow-1"
        >
          <template v-slot:data>
            <span class="caption">{{ `${format(pool.printYourTotalReward)} BLUES` }}</span>
          </template>
        </FilledListItem>
        <FilledListItem
          :caption="$t('yieldPool.manual.available.caption')"
          :tooltip="$t('yieldPool.manual.available.tooltip')"
          :data="`${format(pool.printYouEarned)} BLUES`"
          class="flex-grow-1"
        />
      </div>
    </div>

    <div class="xl:w-12 sm:w-4 sm:pl-s3 xl:p-s0">
      <Button
        :disabled="isCollectDisabled"
        class="w-full button-primary button-lg mt-s3 xl:mt-auto"
        @click="onDialogOpen"
      >
        <Spinner color="white" size="24" class="mr-s2" v-if="isCollectInProgress" />
        {{ isCollectInProgress ? $t('inProgress') : $t('yieldPool.manual.collectEarnings') }}
      </Button>
    </div>

    <Dialog
      v-model:visible="isShowDialog"
      :header="$t('yieldPool.collectConfirmDialog.header')"
      dismissableMask
      :modal="true"
    >
      <CollectModal
        :cancel="onDialogClose"
        :compound="onCompound"
        :harvest="onHarvest"
        :amount="pool.printYouEarned"
        :amountUsd="pool.printYouEarnedUSD"
        :in-progress-compound="inProgressCompound"
        :in-progress-harvest="inProgressHarvest"
      >
      </CollectModal>
    </Dialog>
  </div>
</template>

<script>
import FilledListItem from '@/components/filledList/FilledListItem';
import Spinner from '@/components/Spinner';
import CollectModal from './CollectModal';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import notificationsMixin from '@/mixins/notifications.mixin';
import { mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import LearnMoreDialog from '@/components/highRatesLearnMore/LearnMoreDialog';
import { useBalances } from '@/store/modules/tokens/useBalances';

export default {
  name: 'Collect',
  mixins: [formatNumbersMixin, notificationsMixin],
  components: { LearnMoreDialog, CollectModal, FilledListItem, Spinner },
  props: {
    pool: Object,
  },
  setup() {
    const { updateTokenBalances } = useBalances();

    return {
      updateTokenBalances,
    };
  },
  data() {
    return {
      isShowDialog: false,
      disableButton: false,
      inProgressCompound: false,
      inProgressHarvest: false,
    };
  },
  computed: {
    isCollectDisabled() {
      return !this.pool.earned.gt(0) || this.isCollectInProgress;
    },
    isCollectInProgress() {
      return this.inProgressCompound || this.inProgressHarvest;
    },
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      compound: STAKING_ACTION_TYPES.STAKING_COMPOUND,
      harvest: STAKING_ACTION_TYPES.STAKING_HARVEST,
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
    }),
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    async updateAfterTransaction() {
      await this.updateTokenBalances();
      await this.getStatusStaking();
      await this.updateStakingPools();
    },
    async onHarvest() {
      this.inProgressHarvest = true;
      this.onDialogClose();
      const harvestingTemplate = `
        ${this.$t('harvestingEarnings')} ${this.$t('from')} ${this.$t('manualLqfPool')}
      `;
      const harvestingSucceededTemplate = `${harvestingTemplate} ${this.$t('succeeded')}`;
      const harvestingFailedTemplate = `${harvestingTemplate} ${this.$t('failed')}`;
      this.showInProgressNotification(harvestingTemplate, 'yieldPools');
      await this.harvest({ pool: this.pool })
        .then(async () => {
          await this.updateAfterTransaction();
          this.closeNotifications('yieldPools');
          this.showSuccessNotification(harvestingSucceededTemplate, 'yieldPools');
        })
        .finally(() => {
          this.inProgressHarvest = false;
        })
        .catch(() => {
          this.closeNotifications('yieldPools');
          this.showErrorNotification(harvestingFailedTemplate, 'yieldPools');
        });
      this.inProgressHarvest = false;
    },
    async onCompound() {
      this.inProgressCompound = true;
      this.onDialogClose();
      const compoundingTemplate = `
        ${this.$t('compoundingEarnings')} ${this.$t('from')} ${this.$t('manualLqfPool')}
      `;
      const compoundingSucceededTemplate = `${compoundingTemplate} ${this.$t('succeeded')}`;
      const compoundingFailedTemplate = `${compoundingTemplate} ${this.$t('failed')}`;
      this.showInProgressNotification(compoundingTemplate, 'yieldPools');
      await this.compound({ pool: this.pool })
        .then(async () => {
          await this.updateAfterTransaction();
          this.closeNotifications('yieldPools');
          this.showSuccessNotification(compoundingSucceededTemplate, 'yieldPools');
        })
        .finally(() => {
          this.inProgressCompound = false;
        })
        .catch(() => {
          this.closeNotifications('yieldPools');
          this.showErrorNotification(compoundingFailedTemplate, 'yieldPools');
        });
      this.inProgressCompound = false;
    },
  },
};
</script>

<style scoped></style>
