<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="description" name="description" :content="description" />
    <meta property="keywords" name="keywords" :content="keywords" />
  </Head>
  <div class="flex flex-column flex-grow-1">
    <AprBanner section="yieldPools" :tvlAmount="tvlAmount" />

    <div class="panel-frame mt-s3">
      <template v-if="isAutoStakingStoreReady && isStakingStoreReady">
        <AutoBounty
          v-for="(autoStake, index) in getAutoStakingTableData"
          :auto-pool="autoStake"
          :key="`autobounty-${index}`"
        />
        <AutoPool
          auto-staking
          v-for="(autoStake, index) in getAutoStakingTableData"
          :pool="autoStake"
          :key="`auto-${index}`"
          class="mt-s3"
        />
        <Pool
          v-for="(stake, index) in getStakingTableData"
          :pool="stake"
          :key="index"
          class="mt-s3"
        />
      </template>

      <PoolsLoadingPlaceholder v-else />
    </div>
  </div>
</template>

<script>
import AutoBounty from './autoBounty/autoBounty';
//import Spinner from '@/components/Spinner';
import Pool from './pool/Pool';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { mapGetters, mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { SEO_STAKING } from '@/constants/seo';
import { Head } from '@vueuse/head';
import AprBanner from '@/components/aprBanner/AprBanner';
import AutoPool from '@/views/pages/investment/staking/pool/autoPool/AutoPool';
import { asyncFnCancelable } from '@/utils/promise';
import PoolsLoadingPlaceholder from '@/views/pages/investment/staking/PoolsLoadingPlaceholder.vue';

export default {
  name: 'Staking',
  components: { PoolsLoadingPlaceholder, AutoPool, AprBanner, Pool, AutoBounty, Head },
  data() {
    return {
      isPools: true,
      updateController: new AbortController(),
      updateInterval: null,
    };
  },
  watch: {
    '$store.state.wallet.isInjected': {
      handler: async function (val) {
        if (val) {
          await Promise.allSettled([this.initStaking(), this.initAutoStaking()]);
          if (!this.updateInterval) {
            this.updateInterval = setInterval(async () => {
              const staking = async () => {
                await this.getStatusStaking();
                await this.updateStakingPools();
              };
              const autoStaking = async () => {
                await this.getStatusAutoStaking();
                await this.updateAutoStaking();
              };

              await Promise.allSettled([staking(), autoStaking()]);
            }, UPDATE_INTERVAL);
          }
        } else {
          await Promise.allSettled([this.initStaking(), this.initAutoStaking()]);
          // await this.getPairsWithBalances();
        }
      },
      immediate: true,
    },
  },
  async created() {
    try {
      await asyncFnCancelable(this.getTvlAmount, this.updateController.signal);
      this.updateInterval = setInterval(() => {
        this.getTvlAmount();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  beforeUnmount() {
    this.updateController.abort();
    this.updateController = null;

    console.log('beforeUnmount', this.updateInterval);
    clearInterval(this.updateInterval);
    this.updateInterval = null;
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      initStaking: STAKING_ACTION_TYPES.STAKING_INIT,
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
    }),
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      initAutoStaking: AUTO_STAKING_ACTION_TYPES.AUTO_STAKING_INIT,
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStaking: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
    }),
    ...mapActions(MODULE_NAMES.FARMING, {
      getTvlAmount: FARMING_ACTION_TYPES.GET_CURRENT_TOTAL_TVL,
    }),
  },
  computed: {
    ...mapGetters(MODULE_NAMES.STAKING, ['getStakingTableData']),
    ...mapGetters(MODULE_NAMES.AUTO_STAKING, ['getAutoStakingTableData']),
    tvlAmount() {
      return this.$store.state.farming.tvlAmount;
    },
    isStakingStoreReady() {
      return this.$store.state.staking.isStoreReady;
    },
    isAutoStakingStoreReady() {
      return this.$store.state.autoStaking.isStoreReady;
    },
    title() {
      return SEO_STAKING.TITLE;
    },
    description() {
      return SEO_STAKING.DESCRIPTION;
    },
    keywords() {
      return SEO_STAKING.KEYWORDS;
    },
  },
};
</script>

<style scoped></style>
