<template>
  <div class="panel-info md:flex justify-content-between">
    <div class="flex align-items-center">
      <Icon name="coins" class="mr-s2" size="20" />
      <div>
        <div class="title">
          <span> {{ $t('yieldPool.autoBounty.caption') }}</span>
          <TooltipIcon :tooltip="$t('yieldPool.autoBounty.tooltip')" />
        </div>
        <div class="caption mt-s1">{{ $t('yieldPool.autoBounty.subtitle') }}</div>
      </div>
    </div>
    <Divider v-if="$store.state.layout.isMobile" class="mb-s6 divider-nm" />
    <div class="sm:mx-auto">
      <div class="caption mb-s1">{{ $t('yieldPool.autoBounty.availableClaimAmount') }}</div>
      <div>
        {{ printClaim() }} BLUES
        <span class="text-muted">/ ${{ printClaimUSD() }}</span>
      </div>
    </div>
    <div class="md:align-self-center">
      <Wallet
        ref="wallet"
        button-class="w-full button-primary mt-s6 md:mt-s0 md:ml-auto"
        v-if="!$store.state.wallet.isInjected"
      />
      <Button
        class="w-full button-primary mt-s6 md:mt-s0"
        v-else
        @click="onDialogOpen"
        :disabled="inProgress"
      >
        <Spinner color="white" size="24" class="mr-s2" v-if="inProgress" />
        {{ inProgress ? $t('inProgress') : $t('yieldPool.autoBounty.claimBlues') }}
      </Button>
    </div>
  </div>
  <Dialog
    v-model:visible="isShowDialog"
    :header="$t('yieldPool.autoBounty.confirmDialog.header')"
    dismissableMask
    :modal="true"
    :content-class="$store.state.layout.isMobile ? 'scroll-container' : ''"
  >
    <Confirmation
      :amount="printClaim()"
      :amountUsd="printClaimUSD()"
      :in-progress="inProgress"
      :cancel="onDialogClose"
      :confirm="onConfirm"
    >
    </Confirmation>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/icon/Icon';
import Confirmation from './Confirmation';
import { AutoStaking } from '@/sdk/entities/autoStaking';
import { MODULE_NAMES } from '@/store';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';
import Spinner from '@/components/Spinner';
import Wallet from '@/views/layout/wallet/Wallet';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import notificationsMixin from '@/mixins/notifications.mixin';
import { useBalances } from '@/store/modules/tokens/useBalances';

export default {
  name: 'autoBounty',
  mixins: [formatNumbersMixin, notificationsMixin],
  components: { TooltipIcon, Confirmation, Icon, Wallet, Spinner },
  props: {
    autoPool: AutoStaking,
  },
  setup() {
    const { updateTokenBalances } = useBalances();

    return {
      updateTokenBalances,
    };
  },
  data() {
    return {
      isShowDialog: false,
      inProgress: false,
    };
  },
  methods: {
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStakingPools: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
      claimAutoStaking: AUTO_STAKING_ACTION_TYPES.CLAIM_AUTO_STAKING,
    }),
    printClaim() {
      return this.format(this.autoPool.printClaim);
    },
    printClaimUSD() {
      return this.format(this.autoPool.printClaimUSD);
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    async onConfirm() {
      this.inProgress = true;
      this.onDialogClose();
      const claimingTemplate = `${this.$t('claimingAutoBounty')}`;
      const claimingSucceededTemplate = `${claimingTemplate} ${this.$t('succeeded')}`;
      const claimingFailedTemplate = `${claimingTemplate} ${this.$t('failed')}`;
      this.showInProgressNotification(claimingTemplate, 'autoBounty');
      try {
        await this.claimAutoStaking();

        await this.updateTokenBalances();
        await this.getStatusAutoStaking();
        await this.updateAutoStakingPools();
        this.closeNotifications('autoBounty');
        this.showSuccessNotification(claimingSucceededTemplate, 'autoBounty');
      } catch (error) {
        console.log('[AUTO BOUNTY] Happen error: ', error);
        this.closeNotifications('autoBounty');
        this.showErrorNotification(claimingFailedTemplate, 'autoBounty');
      }
      this.inProgress = false;
    },
  },
};
</script>

<style scoped></style>
