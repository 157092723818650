<template>
  <ControlPanelCollapse :contract-url="getContractUrl()" toggle-inline get-blues-button>
    <template v-slot:header-title>
      <PoolTitle auto-staking />
    </template>

    <template v-slot:header-data>
      <div class="flex flex-wrap gutter-y-s3 flex-grow-1 xl:justify-content-center">
        <div class="w-12 sm:w-4 xl:col-offset-1">
          <Caption
            :caption="$t('yieldPool.auto.apy.caption')"
            :tooltip="$t('yieldPool.auto.apy.tooltip')"
            class="mb-s1"
          />
          <div class="flex mr-s2 align-items-center">
            <span>{{ printPercent(pool.printApy) }}</span>
            <LearnMoreDialog data-source="staking" type="APY" as-icon />
          </div>

          <div class="text-sm text-primary mt-s1 text-bolder" v-if="!isAprLTooLow">
            {{ $t('yieldPool.auto.percentAsHodler', { percent: aprL }) }}
          </div>
        </div>
        <div class="w-12 sm:w-4 lg:w-3">
          <Caption
            :caption="$t('yieldPool.auto.poolCompound.caption')"
            :tooltip="$t('yieldPool.auto.poolCompound.tooltip')"
            class="mb-s1 md:mb-s1"
          />

          <div class="mr-s2">{{ format(pool.printTotalStake) }} BLUES</div>
        </div>
        <div class="w-12 sm:w-4 lg:w-3">
          <Caption
            :caption="$t('yieldPool.auto.newRewards.caption')"
            :tooltip="$t('yieldPool.auto.newRewards.tooltip')"
            class="mb-s1 md:mb-s1"
          />
          <div class="mr-s2">{{ format(pool.printYouEarned) + ' BLUES' }}</div>
        </div>
      </div>
    </template>

    <div class="flex">
      <div class="w-12">
        <HodlBanner class="mb-s3" @openDocs="openDocs" />
        <AutoPoolStake :auto-staking="autoStaking" :pool="pool" />
      </div>
    </div>
  </ControlPanelCollapse>
</template>

<script>
import BigNumber from 'bignumber.js';
import PoolTitle from '@/views/pages/investment/staking/pool/poolTitle/PoolTitle';
import Caption from '@/components/Caption';
import AutoPoolStake from '@/views/pages/investment/staking/pool/autoPool/AutoPoolStake';
import roundMixin from '@/mixins/round.mixin';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import ControlPanelCollapse from '@/components/ControlPanelCollapse';
import { getScanLink } from '@/sdk/utils';
import HodlBanner from '@/views/pages/investment/staking/pool/autoPool/hodlBanner/HodlBanner';
import { MIN_HODLER_APR } from '@/store/modules/staking/constants/AUTO_STAKING';
import LearnMoreDialog from '@/components/highRatesLearnMore/LearnMoreDialog';

export default {
  name: 'AutoPool',
  mixins: [roundMixin, formatNumbersMixin],
  components: {
    LearnMoreDialog,
    HodlBanner,
    AutoPoolStake,
    PoolTitle,
    Caption,
    ControlPanelCollapse,
  },
  props: {
    pool: Object,
    autoStaking: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    printPercent(val) {
      if (val.lt(1)) {
        return `< 1%`;
      }
      return val.gt(10000) ? `> 10000%` : `${this.mathMixRound(val, 2)}%`;
    },
    getContractUrl() {
      return getScanLink(this.pool.farm, 'address');
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    openDocs() {
      this.openExternalLink(
        'https://blueshift-fi.gitbook.io/product-docs/product-concept/yield-pools/auto-hodl-blues-pool',
      );
    },
  },
  computed: {
    aprL() {
      const APRRounded = this.pool.printHodlerAPR
        .multipliedBy(100)
        .toFixed(2, BigNumber.ROUND_DOWN);
      return this.format(APRRounded, 'unlimited');
    },
    isAprLTooLow() {
      return this.pool.printHodlerAPR.multipliedBy(100).lt(MIN_HODLER_APR);
    },
  },
};
</script>

<style scoped lang="scss"></style>
